import { request } from './index'
/**
 *
 * @param {*} data  获取任务筛选的应用平台接口
 * @returns
 */
export function getTaskPlatform(data) {
  return request({
    method: 'post',
    url: '/task/TaskPlatform',
    data
  })
}

/**
 * 任务列表频道筛选
 */
export function getTaskCategory(data) {
  return request({
    method: 'post',
    url: '/task/TaskCategory',
    data
  })
}
/**
 * 任务列表筛选：风格
 */
export function getTaskBookStyle(data) {
  return request({
    method: 'post',
    url: '/task/TaskBookStyle',
    data
  })
}
/**
 *
 * @param {*} data 获取任务列表筛选 性别
 * @returns
 */
export function getTaskBookSex(data) {
  return request({
    method: 'post',
    url: '/task/TaskBookSex',
    data
  })
}
/**
 * 任务列表筛选 推荐标签
 */
export function getRecommendList(data) {
  return request({
    method: 'post',
    url: '/task/RecommendType',
    data
  })
}
/**
 *
 * @param {*} data 获取任务列表筛选 类型
 * @returns
 */
export function getTaskCategoryList(data) {
  return request({
    method: 'post',
    url: '/task/TaskCategoryList',
    data
  })
}
/**
 * 获取用户推广图书列表
 */
export function getUserBookList(data) {
  return request({
    method: 'post',
    url: '/user/taskBook',
    data
  })
}

/**
 * 获取任务推广平台 ,没有账号的渠道也显示
 */
export function getPromotionPlatform(data) {
  return request({
    method: 'post',
    url: '/user/PromotionPlatform',
    data
  })
}

/**
 * 获取任务推广平台 ,没有账号的渠道不显示
 */
export function getPromotionPlatformFilter(data) {
  return request({
    method: 'post',
    url: '/user/UserPromotion',
    data
  })
}
/**
 * 图片上传
 */
export function uploadImage(data) {
  return request({
    method: 'post',
    url: '/upload/imageUpload',
    data
  })
}

/**
 * 内容复制
 */
export function geCopyContent(params) {
  return request({
    method: 'get',
    url: '/zhihu/copy/content',
    params
  })
}
